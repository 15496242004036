import React, { useState, useEffect } from 'react';
import colorfulHeart from '../../images/colorful-heart-air-balloon-shape-collection-concept-isolated-color-background-beautiful-heart-ball-event_90220-1047.avif';

const PuzzleGame = ({ onComplete }) => {
  const [pieces, setPieces] = useState([]);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const imageSrc = colorfulHeart;
    const pieceCount = 9;
    const newPieces = Array.from({ length: pieceCount }, (_, index) => ({
      id: index,
      src: imageSrc,
      position: index,
      currentPosition: index
    }));
    setPieces(shuffleArray(newPieces));
  }, []);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i].currentPosition, shuffled[j].currentPosition] = [shuffled[j].currentPosition, shuffled[i].currentPosition];
    }
    return shuffled;
  };

  const handlePieceClick = (clickedPiece) => {
    const updatedPieces = pieces.map(piece => 
      piece.id === clickedPiece.id 
        ? { ...piece, currentPosition: (piece.currentPosition + 1) % 9 } 
        : piece
    );
    setPieces(updatedPieces);
    checkCompletion(updatedPieces);
  };

  const checkCompletion = (currentPieces) => {
    const isCompleted = currentPieces.every(piece => piece.position === piece.currentPosition);
    if (isCompleted) {
      setCompleted(true);
      setTimeout(onComplete, 1000);
    }
  };

  return (
    <div className="container">
      <div className="row g-1 mb-4">
        {pieces.map(piece => (
          <div key={piece.id} className="col-4">
            <div 
              className="w-100 cursor-pointer"
              style={{
                paddingBottom: '100%',
                backgroundImage: `url(${piece.src})`,
                backgroundPosition: `${(piece.currentPosition % 3) * 50}% ${Math.floor(piece.currentPosition / 3) * 50}%`,
                backgroundSize: '300% 300%',
              }}
              onClick={() => handlePieceClick(piece)}
            />
          </div>
        ))}
      </div>
      {completed && <div className="text-center mt-4">Puzzle complété !</div>}
    </div>
  );
};

export default PuzzleGame;