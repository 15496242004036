import React, { useState } from 'react';
import LoveJourneyGame from './components/LoveJourneyGame';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'Kev+Ray=Love';

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Mot de passe incorrect.');
    }
  };

  if (isAuthenticated) {
    return (
      <div className="App">
        <LoveJourneyGame />
      </div>
    );
  }

  return (
    <div className="container-fluid d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="card" style={{ width: '300px' }}>
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Entrez le mot de passe</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passse"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Accéder
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;