import React, { useState, useEffect } from 'react';

const MazeGame = ({ onComplete }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mazeSize = 4;

  const maze = [
    [1, 1, 0, 0],
    [0, 1, 0, 0],
    [0, 1, 1, 0],
    [0, 0, 1, 2],
  ];

  // 0: mur, 1: chemin, 2: arrivée

  const handleMove = (dx, dy) => {
    const newX = position.x + dx;
    const newY = position.y + dy;

    if (newX >= 0 && newX < mazeSize && newY >= 0 && newY < mazeSize && maze[newY][newX] !== 0) {
      setPosition({ x: newX, y: newY });
      if (maze[newY][newX] === 2) {
        onComplete();
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          handleMove(0, -1);
          break;
        case 'ArrowDown':
          handleMove(0, 1);
          break;
        case 'ArrowLeft':
          handleMove(-1, 0);
          break;
        case 'ArrowRight':
          handleMove(1, 0);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [position]);

  return (
    <div className="container">
      <div className="row g-1 mb-2">
        {maze.map((row, y) => (
          row.map((cell, x) => (
            <div key={`${x}-${y}`} className="col-3">
              <div
                className={`w-100 ${
                  x === position.x && y === position.y
                    ? 'bg-danger'
                    : cell === 2
                    ? 'bg-success'
                    : cell === 1
                    ? 'bg-light'
                    : 'bg-secondary'
                }`}
                style={{ paddingBottom: '100%', border: '1px solid #ddd' }}
              />
            </div>
          ))
        ))}
      </div>
      <div className="d-flex justify-content-center gap-2">
        <button className="btn btn-primary" onClick={() => handleMove(0, -1)}>↑</button>
        <button className="btn btn-primary" onClick={() => handleMove(0, 1)}>↓</button>
        <button className="btn btn-primary" onClick={() => handleMove(-1, 0)}>←</button>
        <button className="btn btn-primary" onClick={() => handleMove(1, 0)}>→</button>
      </div>
      <p className="text-center mt-3">Utilisez les flèches du clavier ou les boutons pour déplacer le point rouge vers le point vert.</p>
    </div>
  );
};

export default MazeGame;