import React, { useState, useEffect, useCallback } from 'react';

const HeartCatcherGame = ({ onComplete }) => {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [hearts, setHearts] = useState([]);
  const [gameOver, setGameOver] = useState(false);

  const endGame = useCallback(() => {
    setGameOver(true);
  }, []);

  useEffect(() => {
    if (gameOver) {
      onComplete();
    }
  }, [gameOver, onComplete]);

  useEffect(() => {
    if (gameOver) return;

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          endGame();
          return 0;
        }
        return prev - 1;
      });

      if (Math.random() < 0.8) { // Probabilité
        setHearts(prev => [...prev, { 
          id: Date.now(), 
          x: Math.random() * 90,
          y: 0, 
          broken: Math.random() < 0.3,
          speed: 0.4 + Math.random() * 0.5 // Vitesse
        }]);
      }
    }, 1000);

    const moveHearts = setInterval(() => {
      setHearts(prev => prev.map(heart => ({
        ...heart,
        y: heart.y + heart.speed
      })).filter(heart => heart.y < 100));
    }, 50);

    return () => {
      clearInterval(timer);
      clearInterval(moveHearts);
    };
  }, [gameOver, endGame]);

  useEffect(() => {
    if (score >= 8 && !gameOver) {
      endGame();
    }
  }, [score, gameOver, endGame]);

  const handleHeartClick = useCallback((heart) => {
    if (gameOver) return;
    setHearts(prev => prev.filter(h => h.id !== heart.id));
    if (!heart.broken) {
      setScore(prev => prev + 1);
    } else {
      setScore(prev => Math.max(0, prev - 1));
    }
  }, [gameOver]);

  return (
    <div className="container">
      <div className="position-relative border border-secondary" style={{ height: '300px', overflow: 'hidden' }}>
        <div className="position-absolute top-0 start-0 m-2">Score: {score}</div>
        <div className="position-absolute top-0 end-0 m-2">Temps: {timeLeft}s</div>
        {hearts.map(heart => (
          <div
            key={heart.id}
            className="position-absolute cursor-pointer"
            style={{ left: `${heart.x}%`, top: `${heart.y}%`, fontSize: 20 }}
            onClick={() => handleHeartClick(heart)}
          >
            {heart.broken ? '💔' : '❤️'}
          </div>
        ))}
      </div>
      {gameOver && (
        <div className="text-center mt-3">
          {score >= 10 ? "Bravo ! Vous avez gagné !" : `Temps écoulé ! Votre score : ${score}`}
        </div>
      )}
    </div>
  );
};

export default HeartCatcherGame;