import React, { useState, useEffect, useCallback } from 'react';

const MemoryGame = ({ onComplete }) => {
  const [cards, setCards] = useState([]);
  const [flipped, setFlipped] = useState([]);
  const [solved, setSolved] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);

  useEffect(() => {
    const symbols = ['❤️', '💖', '💕', '💓', '💗', '💘'];
    const newCards = [...symbols, ...symbols]
      .sort(() => Math.random() - 0.5)
      .map((symbol, index) => ({ id: index, symbol, flipped: false, solved: false }));
    setCards(newCards);
  }, []);

  const checkCompletion = useCallback(() => {
    if (solved.length === 5 && !gameCompleted) {
      setGameCompleted(true);
      onComplete();
    }
  }, [solved.length, gameCompleted, onComplete]);

  useEffect(() => {
    checkCompletion();
  }, [solved, checkCompletion]);

  const handleCardClick = (id) => {
    if (disabled || gameCompleted) return;
    const newFlipped = [...flipped, id];
    setFlipped(newFlipped);

    if (newFlipped.length === 2) {
      setDisabled(true);
      const [first, second] = newFlipped;
      if (cards[first].symbol === cards[second].symbol) {
        setSolved(prev => [...prev, cards[first].symbol]);
        setFlipped([]);
        setDisabled(false);
      } else {
        setTimeout(() => {
          setFlipped([]);
          setDisabled(false);
        }, 1000);
      }
    }
  };

  return (
    <div className="container">
      <div className="row row-cols-3 g-2 mb-4">
        {cards.map(card => (
          <div key={card.id} className="col" style={{ height: '60px' }}>
            <div
              className={`card h-100 d-flex align-items-center justify-content-center fs-2 cursor-pointer ${
                flipped.includes(card.id) || solved.includes(card.symbol) ? 'bg-light' : 'bg-secondary'
              }`}
              style={{ height: '60px' }}
              onClick={() => handleCardClick(card.id)}
            >
              {(flipped.includes(card.id) || solved.includes(card.symbol)) ? card.symbol : ''}
            </div>
          </div>
        ))}
      </div>
      {gameCompleted && <div className="text-center mt-3">Bravo ! Vous avez terminé le jeu de mémoire !</div>}
    </div>
  );
};

export default MemoryGame;