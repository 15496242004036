import React, { useState } from 'react';
import guyanaMap from '../../images/carte-guyane-francaise.png';

const GuyanaMapGame = ({ onComplete }) => {
  const [clickedPoints, setClickedPoints] = useState([]);
  const totalPoints = 5;

  const handleMapClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setClickedPoints(prev => [...prev, { x, y }]);

    if (clickedPoints.length + 1 === totalPoints) {
      setTimeout(onComplete, 1000);
    }
  };

  return (
    <div className="container">
      <div className="position-relative mb-4">
        <img
          src={guyanaMap}
          alt="Carte de la Guyane"
          className="w-100 h-auto cursor-pointer"
          onClick={handleMapClick}
        />
        {clickedPoints.map((point, index) => (
          <div
            key={index}
            className="position-absolute rounded-circle bg-danger"
            style={{ left: point.x - 8, top: point.y - 8, width: '16px', height: '16px' }}
          />
        ))}
      </div>
      <p className="text-center">Clique sur {totalPoints - clickedPoints.length} endroits spéciaux en Guyane</p>
    </div>
  );
};

export default GuyanaMapGame;