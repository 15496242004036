import React, { useCallback, useState } from 'react';
import { Heart } from 'lucide-react';

import PuzzleGame from './games/PuzzleGame';
import MemoryGame from './games/MemoryGame';
import HeartCatcherGame from './games/HeartCatcherGame';
import MazeGame from './games/MazeGame';
import GuyanaMapGame from './games/GuyanaMapGame';

const LoveJourneyGame = () => {
    const [gameStarted, setGameStarted] = useState(false);
  const [stage, setStage] = useState(0);
  const [showHearts, setShowHearts] = useState(false);
  const [storyParts, setStoryParts] = useState([]);
  const [gameCompleted, setGameCompleted] = useState(false);

  const stages = [
    {
      title: "Les pensées secrètes",
      game: PuzzleGame,
      story: "Je pense beaucoup à un homme depuis quelque temps. Il est intelligent, attentionné, drôle, beau, sexy (vraiment sexy), il me fait beaucoup de bien... Cela faisait longtemps que je n'avais rien ressenti de tel.",
      instruction: "Reconstitue le puzzle en cliquant sur les pièces pour les faire pivoter."
    },
    {
      title: "La première rencontre",
      game: MemoryGame,
      story: "Lors de notre première rencontre, il s'est passé quelque chose lorsque nos regards se sont croisés pour la première fois. Je m'en souviendrai toujours : cette sensation de bien-être, comme si j'avais retrouvé ce bout qui me manquait, sans même le savoir.",
      instruction: "Trouve toutes les paires de symboles d'amour en retournant les cartes."
    },
    {
      title: "Des jours magiques",
      game: HeartCatcherGame,
      story: "Nous avons passé des jours merveilleux ensemble. Notre premier baiser fut magique, j'étais si heureux... Heureux de l'avoir trouvé, heureux d'être avec lui, de pouvoir profiter de ces moments tous les deux, comme si plus rien ni personne n'existait, comme s'il n'y avait que nous sur Terre.",
      instruction: "Attrape les cœurs entiers et évite les cœurs brisés. Atteins 8 points pour gagner !"
    },
    {
      title: "Le départ imminent",
      game: MazeGame,
      story: "Oui, lui, mon lui, il doit partir, il ne vit pas ici. Cependant, durant ces quelques jours, je lui ai donné ma parole : je le suivrai où qu'il aille. Donc dans 4 mois, très bientôt, je serai avec lui, en Guyane.",
      instruction: "Guide le cœur rouge à travers le labyrinthe jusqu'au point vert."
    },
    {
      title: "La promesse",
      game: GuyanaMapGame,
      story: "Cet homme s'appelle Rayan Adelphe Coupra, et j'espère qu'un jour il s'appellera Rayan Adelphe Coupra-Crane. Je suis profondément amoureux de cet homme.",
      instruction: "Clique sur 5 endroits de la carte de Guyane où tu aimerais qu'on aille ensemble. <b>Et fais une capture d'écran pour moi.</b>"
    }
  ];

  const handleGameComplete = useCallback(() => {
    if (!gameCompleted) {
      setGameCompleted(true);
      setStoryParts(prevParts => [...prevParts, stages[stage].story]);
    }
  }, [gameCompleted, stage, stages]);

  const handleContinue = () => {
    if (stage < stages.length - 1) {
      setStage(stage + 1);
      setGameCompleted(false);
    } else {
      setShowHearts(true);
    }
  };

  const startGame = () => {
    setGameStarted(true);
  };

  if (!gameStarted) {
    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100">
          <div className="card" style={{ width: '100%', maxWidth: '500px' }}>
            <div className="card-header">Bonjour Rayan !</div>
            <div className="card-body">
              <p className="card-text">
                Ce jeu est un petit cadeau de ma part. J'ai voulu te raconter quelque chose d'important, mais je me suis dit qu'il serait plus sympa de rendre ça interactif.
              </p>
              <p className="card-text">
                À chaque étape, tu découvriras une partie de l'histoire, et crois-moi, ça te concerne de très près !
              </p>
              <p className="card-text">Voici comment ça fonctionne :</p>
              <ul>
                <li>Tu vas jouer à 5 mini-jeux différents.</li>
                <li>Chaque mini-jeu représente une étape de notre histoire.</li>
                <li>Une fois le mini-jeu terminé, tu débloqueras une partie de l'histoire.</li>
                <li>À la fin, tu auras l'histoire complète et une surprise !</li>
              </ul>
              <p className="card-text">Es-tu prêt à commencer cette aventure ?</p>
              <button className="btn btn-primary w-100" onClick={startGame}>Commencer le jeu</button>
            </div>
          </div>
        </div>
    );
  }

  const CurrentGame = stages[stage].game;

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100">
      <div className="card mb-4" style={{ width: '100%', maxWidth: '500px' }}>
        <div className="card-header">{stages[stage].title}</div>
        <div className="card-body">
          {/* <p className="card-text mb-3">{stages[stage].instruction}</p> */}
          <p className="card-text mb-3" dangerouslySetInnerHTML={{ __html: stages[stage].instruction }} />
          <CurrentGame onComplete={handleGameComplete} />
          {gameCompleted && (
            <button className="btn btn-primary w-100 mt-3" onClick={handleContinue}>
              Continuer
            </button>
          )}
        </div>
      </div>
      {storyParts.length > 0 && (
        <div className="card mt-4 mb-5" style={{ width: '100%', maxWidth: '500px' }}>
          <div className="card-header">Notre Histoire</div>
          <div className="card-body">
            {storyParts.map((part, index) => (
              <p key={index} className="card-text">{part}</p>
            ))}
          </div>
        </div>
      )}
      {showHearts && <FinalAnimation />}
    </div>
  );
};

const FinalAnimation = () => {
  return (
    <div className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center" style={{ pointerEvents: 'none' }}>
      <div className="text-center">
        <h2 className="mb-4" style={{color: '#FF0000'}}>Je t'aime, Rayan</h2>
        {[...Array(50)].map((_, i) => (
          <Heart
            key={i}
            size={24}
            className="heart"
            color='#FF0000'
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default LoveJourneyGame;